<template>
  <div class="bookingMap">
    <!--ROOM-->
    <div v-if="showRoom">
      <v-row class="ma-0 pa-0 bookingMap-container">
        <!--左侧地图-->
        <v-col cols="8" class="ma-0 pa-0 bookingMap-left">
          <!--地图标题-->
          <div class="bookingMap-left-title">
            <div>
              <v-btn rounded class="x-btn" @click="back(false)">back</v-btn>
            </div>
            <div class="mapTitle">
              {{ startTime }} To {{ endTime }}
              <!--{{ month }}&nbsp;{{ date }},-->
              <!--{{ startRoom }}&nbsp;to&nbsp;{{ endRoom }}-->
              <!--{{ data.startTime }}&nbsp;to&nbsp;{{ data.endTime }}-->
            </div>
            <div class="mapLocation">
              Current:&nbsp;{{
                data.rooms.length > 0 ? data.rooms[0].buildingName : ""
              }},&nbsp;{{
                data.rooms.length > 0 ? data.rooms[0].floorName : ""
              }}
            </div>
          </div>
          <!--地图-->
          <div class="floorMapBlock" ref="floorMapBlock" v-if="data.rooms[0]">
            <p
              v-if="!(currentCampusName == data.rooms[0].campusName)"
              class="mapTip"
            >
              To see the room/desk location please go to the campus where you
              reserved them.
            </p>
            <canvas
              v-else
              id="floorMap"
              ref="floorMap"
              :width="width"
              :height="height"
            ></canvas>
          </div>
        </v-col>
        <!--右侧room信息-->
        <v-col cols="4" class="bookingMap-right-box">
          <v-row class="ma-0 pa-0 bookingMap-right" v-show="showBookingList">
            <div class="bookingMap-right-container">
              <bookingList
                :height="'80vh'"
                :data="data"
                :showLoaction="false"
                :showParticipants="true"
                @cancelDesk="cancelDesk"
                @editMeeting="editMeeting"
              ></bookingList>
            </div>
          </v-row>
          <v-row class="ma-0 pa-0 bookingMap-right" v-show="showEditMeeting">
            <!-- v-on="$listeners" 用来孙子组件调用方法给爷爷组件传值-->
            <!-- v-bind="$attrs"  用来爷爷组件直接传值给孙子组件-->
            <editMeeting
              ref="editMeeting"
              @back="backToList"
              @jumpToSelectPa="jumpToSelectPa"
              @refreshRoom="refreshRoom"
              v-on="$listeners"
              v-bind="$attrs"
              :data="data"
            ></editMeeting>
            <!--<editMeeting-->
            <!--@back="backToList"-->
            <!--@jumpToSelectPa="jumpToSelectPa"-->
            <!--@cancelPa="cancelPa"-->
            <!--:data="data"-->
            <!--:participants="participants"-->
            <!--&gt;</editMeeting>-->
          </v-row>
          <v-row class="ma-0 pa-0 bookingMap-right" v-show="showSelectPa">
            <selectPa
              @backToBooking="backToBooking"
              @addPa="addPa"
              :pa="pa"
            ></selectPa>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <!--DESK-->
    <div v-else>
      <v-row class="ma-0 pa-0 bookingMap-container">
        <!--左侧地图-->
        <v-col cols="8" class="ma-0 pa-0 bookingMap-left">
          <!--地图标题-->
          <div class="bookingMap-left-title">
            <div>
              <v-btn rounded class="x-btn" @click="back(false)">back</v-btn>
            </div>
            <div class="mapTitle">
              {{ startTime }} To {{ endTime }}
              <!--{{ month }}&nbsp;{{ date }},-->
              <!--{{ start }}&nbsp;to&nbsp;{{ end }}-->
              <!--{{ data.start }}&nbsp;to&nbsp;{{ data.end }}-->
            </div>
            <div class="mapLocation">
              Current:&nbsp;{{ data.buildingName }},&nbsp;{{ data.floorName }}
            </div>
          </div>
          <!--地图-->
          <div class="floorMapBlock" ref="floorMapBlock">
            <canvas
              id="floorMap"
              ref="floorMap"
              :width="width"
              :height="height"
            ></canvas>
          </div>
        </v-col>
        <!--右侧desk信息-->
        <v-col cols="4" class="bookingMap-right-box">
          <v-row class="ma-0 pa-0 bookingMap-right">
            <bookingList
              :height="'80vh'"
              :data="data"
              :showLoaction="false"
              @cancelDesk="cancelDesk"
            ></bookingList>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import axios from "axios";
import bookingList from "@/views/dashboard/componentsHa/bookingList";
import editMeeting from "@/views/dashboard/componentsHa/editMeeting";
import selectPa from "@/views/dashboard/componentsHa/selectPa";

let canvas;
export default {
  name: "bookingMap",
  props: {
    data: {
      type: Object,
    },
    showRoom: {
      type: Boolean,
    },
  },
  components: {
    bookingList,
    editMeeting,
    selectPa,
  },
  watch: {
    data: {
      handler: (newVal, oldVal) => {
        console.log("data变化了", newVal); //但是val和olVal值一样
      },
      deep: true,
    },
    showBookingList(newVal, oldVal) {
      if (newVal) {
        this.showEditMeeting = false;
        this.showSelectPa = false;
      }
    },
    showEditMeeting(newVal, oldVal) {
      if (newVal) {
        this.showBookingList = false;
        this.showSelectPa = false;
      }
    },
    showSelectPa(newVal, oldVal) {
      if (newVal) {
        this.showBookingList = false;
        this.showEditMeeting = false;
      }
    },
  },
  data() {
    return {
      currentCampusName: "", //当前用户所查看的campusName
      pa: [],
      // participants: [],//与会与人
      showBookingList: true,
      showEditMeeting: false,
      showSelectPa: false,
      startTime: "",
      endTime: "",
      width: "",
      height: "",
      campusData: [],
      buildingsAndFloorsData: [],
      buildings: [], //所有建筑及楼层的信息
      floors: [], //当前选中building的所有floor
      currentFloor: "", //当前选中楼层
      scaleSize: 0.3,
      points: [], //当前编辑desk的点的坐标
      pointsArrXY: [], //格式转换后的坐标
      imageW: 0,
      imageH: 0,
      canvasW: 0,
      canvasH: 0,
      month: "",
      date: "",
      start: "",
      end: "",
      startRoom: "",
      endRoom: "",
      dateHash: {
        "01": "Jun",
        "02": "Feb",
        "03": "Mar",
        "04": "Apr",
        "05": "May",
        "06": "Jun",
        "07": "Jul",
        "08": "Aug",
        "09": "Sept",
        10: "Oct",
        11: "Nov",
        12: "Dec",
      },
    };
  },
  methods: {
    fn(ev) {
      ev.preventDefault();
    },
    initCanvas(item) {
      let startTime, endTime;
      let that = this;
      if (canvas) {
        canvas.clear();
        canvas.dispose();
      }
      // 1. 实例化canvas 画布
      canvas = new fabric.Canvas("floorMap");
      // 2. 设置背景图片作为底图（这里导入图片使用require，不要 使用 '../../' 方式）
      canvas.width = this.$refs.floorMapBlock.offsetWidth;
      canvas.height = this.$refs.floorMapBlock.offsetHeight;
      canvas.setHeight(this.$refs.floorMapBlock.offsetHeight);
      canvas.setWidth(this.$refs.floorMapBlock.offsetWidth);
      var center = canvas.getCenter();
      canvas.controlsAboveOverlay = true;

      var url = item.url;
      const image = new window.Image();
      image.src = url;
      image.onload = function () {
        that.imageW = image.width;
        that.imageH = image.height;
        var center = canvas.getCenter();
        that.canvasW = canvas.width;
        that.canvasH = canvas.height;
        fabric.Image.fromURL(url, (img) => {
          img.set({
            opacity: 1,
            scaleX: 1,
            scaleY: 1,
            top: center.top,
            left: center.left,
            originX: "center",
            originY: "center",
          });
          img.scale(that.scaleSize);
          canvas
            .setBackgroundImage(img, canvas.requestRenderAll.bind(canvas))
            .renderAll();
          that.getDeskLocation();
        });
      };
      //鼠标按下事件
      canvas.on("mouse:down", function (e) {
        this.panning = true;
        canvas.selection = false;
      });
      //鼠标抬起事件
      canvas.on("mouse:up", function (e) {
        this.panning = false;
        canvas.selection = true;
      });
      // 移动画布事件
      canvas.on("mouse:move", function (e) {
        if (this.panning && e && e.e) {
          var delta = new fabric.Point(e.e.movementX, e.e.movementY);
          canvas.relativePan(delta);
        }
      });

      // 鼠标滚动画布放大缩小
      canvas.on("mouse:wheel", function (e) {
        let container = document.getElementsByClassName("bookingMap")[0];
        container.addEventListener("mousewheel", that.fn);

        // console.log(event);
        // console.log(canvas.getZoom());
        var zoom = (event.deltaY > 0 ? -0.2 : 0.2) + canvas.getZoom();
        zoom = Math.max(1, zoom); //最小为原来的1
        zoom = Math.min(4, zoom); //最大是原来的5倍
        var zoomPoint = new fabric.Point(event.offsetX, event.offsetY);

        canvas.zoomToPoint(zoomPoint, zoom);
      });

      canvas.on("object:selected", function (obj) {});
      // canvas.defaultCursor = 'crosshair'; //默认光标改成十字
      canvas.hoverCursor = "pointer"; //悬浮光标改成手型
      canvas.preserveObjectStacking = true;
      canvas.renderAll();
    },
    //获取所有building及其floor（前端做筛选）
    getAllMap() {
      this.$axios({
        method: "GET",
        url: "/m/campus/maps",
        data: {
          meetingId: localStorage.meetingIdMap,
          // token: localStorage.token
        },
      })
        .then((res) => {
          this.buildings = res.data.data;
          let length = this.buildings.length;
          for (let i = 0; i < length; i++) {
            let item = this.buildings[i];
            if (this.showRoom) {
              //ROOM
              if (item.buildingName == this.data.rooms[0].buildingName) {
                //筛选出当前building（不在同一个campus订的meeting或desk,地图会为空，因为匹配不上buildingName）
                this.floors = item.floors; //获取当前building的所有floors
                this.getFloor();
                this.initCanvas(this.currentFloor);
                break;
              }
            } else {
              //DESK
              if (item.buildingName == this.data.buildingName) {
                //筛选出当前building
                this.floors = item.floors; //获取当前building的所有floors
                this.getFloor();
                this.initCanvas(this.currentFloor);
                break;
              }
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 筛选出当前楼层
    getFloor() {
      let length = this.floors.length;
      for (let i = 0; i < length; i++) {
        let item = this.floors[i];
        if (this.showRoom) {
          if (item.name == this.data.rooms[0].floorName) {
            this.currentFloor = item;
            break;
          }
        } else {
          if (item.name == this.data.floorName) {
            console.log("====getFlooritem =====", item);
            this.currentFloor = item;
            break;
          }
        }
      }
    },
    // 回到deskList页面
    back(type) {
      // this.participants = [];
      this.$emit("back", type);
    },
    // 获取当前编辑desk的points
    getDeskLocation() {
      console.log("getDeskLocation:");
      let that = this;
      this.$axios({
        // method: 'post',
        // // url: '/m/campusPage/',
        // url: '/m/v2/spaces/map',
        method: "get",
        url: this.$usev2 ? "/m/v2/spaces/map" : "/m/spaces/map",
        data: {
          pageName: "map",
          meetingId: localStorage.meetingIdMap,
          mapId: this.currentFloor.resourceId,
          // token: localStorage.token
        },
      })
        .then((res) => {
          // console.log('getDeskLocation:', res);
          console.log("this.data~~~~~~~:", this.data);
          let resources = res.data.data.resources;
          let length = resources.length;
          for (let i = 0; i < length; i++) {
            let item = resources[i];
            if (this.showRoom) {
              // console.log("item:",item);
              // console.log('item.resourceId:',item.resourceId);
              // console.log('this.data.rooms[0].resourceId:',this.data.rooms[0].resourceId);
              if (
                item.resourceId == this.data.rooms[0].resourceId ||
                item.resourceId == this.data.rooms[0].resourceEmail
              ) {
                console.log("points:", item.points);
                this.points = item.points;
                this.pointsArrXY = [];
                item.points.forEach((ii) => {
                  let newI = ii.split(",");
                  if (newI != "") {
                    let obj = {};
                    obj.x =
                      newI[0] * this.scaleSize +
                      Number(that.canvasW / 2) -
                      Number((that.imageW * this.scaleSize) / 2);
                    obj.y =
                      newI[1] * this.scaleSize +
                      Number(that.canvasH / 2) -
                      Number((that.imageH * this.scaleSize) / 2);
                    this.pointsArrXY.push(obj);
                  }
                });
                this.drawDesk();
                break;
              }
            } else {
              if (
                item.resourceId == this.data.resourceId ||
                item.resourceId == this.data.resourceEmail
              ) {
                // console.log('找到了！');
                // console.log('points:', item.points);
                this.points = item.points;
                this.pointsArrXY = [];
                item.points.forEach((ii) => {
                  let newI = ii.split(",");
                  if (newI != "") {
                    let obj = {};
                    obj.x =
                      newI[0] * this.scaleSize +
                      Number(that.canvasW / 2) -
                      Number((that.imageW * this.scaleSize) / 2);
                    obj.y =
                      newI[1] * this.scaleSize +
                      Number(that.canvasH / 2) -
                      Number((that.imageH * this.scaleSize) / 2);
                    this.pointsArrXY.push(obj);
                  }
                });
                this.drawDesk();
                break;
              }
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 高亮当前desk
    drawDesk() {
      console.log("pointsArrXY:", this.pointsArrXY);
      let color = "#4164FD";
      if (this.client == "NatWest") {
        color = "#FB7182";
      }
      let polygon = new fabric.Polygon(this.pointsArrXY, {
        // fill: '#4164FD',
        fill: color,
        hasControls: false,
        opacity: 0.5,
        hasBorders: true,
        id: "oldDesk",
      });
      canvas.add(polygon);
      canvas.renderAll();
    },
    // 取消预定
    // cancelDesk(data) {
    //   console.log("data:",data)
    //   if (data.type == 'meeting') {
    //     let eventId = data.eventId;
    //     let calendarId = localStorage.userEmail;
    //     this.$confirm({
    //       title: "Confirmation",
    //       message: "Are you sure you would like to cancel this reservation?",
    //       show: true,
    //       showTwoBtn:true
    //     })
    //       .then(() => {
    //         this.bus.$emit('loading', true);
    //         //用户点击确认后执行
    //         this.$axios({
    //           method: 'GET',
    //           url: '/m/spaces/deleteEvent/',
    //           params: {
    //             meetingId: localStorage.meetingIdMap,
    //             eventId: eventId,
    //             calendarId: calendarId,
    //             token: localStorage.token
    //           }
    //         }).then(res => {
    //           // console.log('res:', res);
    //           // this.$message.success("Success");
    //           this.back(data);
    //           if(window.gtag){
    //             window.gtag('event', 'Bookings', {
    //               event_category: 'Cancel Meeting Click',
    //               event_label: 'Cancel Meeting',
    //               // event_label: data.rooms[0].resourceName,
    //               send_to: localStorage.getItem("googleAnaId")||"",
    //               dimension1:localStorage.getItem("campusName")||""
    //             })
    //           }
    //
    //           // this.getUserSeats();
    //         }).catch(err => {
    //           this.bus.$emit('loading', false);
    //           console.log(err);
    //         })
    //       })
    //       .catch(() => {
    //         // 取消或关闭
    //
    //       });
    //   }
    //   else {
    //     let reservationId = data.reservation_id;
    //     this.$confirm({
    //       title: "Confirmation",
    //       message: "Are you sure you would like to cancel this reservation?",
    //       show: true,
    //       showTwoBtn:true
    //     })
    //       .then(() => {
    //         this.bus.$emit('loading', true);
    //         //用户点击确认后执行
    //         this.$axios({
    //           method: 'post',
    //           url: '/m/desk/cancelSeat',
    //           params: {
    //             meetingId: localStorage.meetingIdMap,
    //             reservationId: reservationId,
    //             token: localStorage.token
    //           }
    //         }).then(res => {
    //           // this.$message.success("Success");
    //           this.back(data);
    //           // this.getUserSeats();
    //           if(window.gtag){
    //             window.gtag('event', 'Bookings', {
    //               event_category: 'Cancel Desk Click',
    //               event_label: 'Cancel Desk',
    //               send_to: localStorage.getItem("googleAnaId")||"",
    //               dimension1:localStorage.getItem("campusName")||""
    //             })
    //           }
    //
    //         }).catch(err => {
    //           this.bus.$emit('loading', false);
    //           console.log(err);
    //         })
    //       })
    //       .catch(() => {
    //         // 取消或关闭
    //
    //       });
    //   }
    // },
    cancelDesk(data) {
      this.$emit("cancelDesk", data);
    },
    // 编辑会议
    editMeeting() {
      this.showEditMeeting = true;
      // if (window.gtag) {
      //   window.gtag("event", "Bookings", {
      //     event_category: "Edit Meeting Click",
      //     event_label: "Edit Meeting",
      //     send_to: localStorage.getItem("googleAnaId") || "",
      //     dimension1: localStorage.getItem("campusName") || "",
      //   });
      // }
      if (this.$gtag.event) {
        this.$gtag.event("Bookings", {
          event_category: "Edit Meeting Click",
          event_label: "Edit Meeting",
          send_to: localStorage.getItem("googleAnaId") || "",
          dimension1: localStorage.getItem("campusName") || "",
        });
      }
    },
    // 右侧切换到roomList
    backToList() {
      this.showBookingList = true;
    },
    jumpToSelectPa(data) {
      this.showSelectPa = true;
      this.pa = data;
    },
    refreshRoom(attendees, data) {
      this.$emit("refreshRoom", attendees, data);
    },
    // refreshRoom(eventId) {
    //   this.$emit("refreshRoom", eventId);
    // },
    backToBooking() {
      this.showEditMeeting = true;
    },
    // 新增与人人员
    addPa(data) {
      let attendees = this.$refs.editMeeting.attendees;
      // let attendees = this.$refs.editMeeting.data.attendees;
      attendees.map((item) => {
        let length = data.length;
        for (let i = 0; i < length; i++) {
          let jtem = data[i];
          if (item.email == jtem.email) {
            data.splice(i, 1);
            break;
          }
        }
      });
      attendees.concat(data);
      let arr = attendees.concat(data);
      this.$refs.editMeeting.attendees = arr;
      // this.$refs.editMeeting.data.attendees = arr;
      this.showEditMeeting = true;
    },
    // 展示booking List
    // showList(){
    //   console.log("???????????????????")
    //   this.showBookingList = true;
    // }
  },
  created() {
    console.log("bookingMap-data!!!!!!!!!!!!!!!!!!!!!!!!!!!!!:", this.data);
    this.client = localStorage.getItem("client");
    //当前用户所查看的campusName
    this.currentCampusName = localStorage.getItem("campusName");
    console.log("currentCampusName", this.currentCampusName);
    console.log("==============", this.data);
    let timezone = localStorage.getItem("timezone");
    if (this.data.hasOwnProperty("seat_id")) {
      // 因为desk后台没有返回时间戳，所以用这个格式
      console.log("deskData:", this.data);
      let start = this.data.start;
      let end = this.data.end;
      var utcDate = moment.utc(start);
      var localDate = moment(utcDate);
      this.startTime = localDate.format("MMM D, h:mm a");
      let utcDateEnd = moment.utc(end);
      let localDateEnd = moment(utcDateEnd);
      this.endTime = localDateEnd.format("MMM D, h:mm a");
    } else {
      if (this.data.description) {
        let str = this.data.description.replaceAll("\n", "<br/>");
        this.description = str;
      }
      // 因为room后台有返回时间戳，所以用这个格式
      if (timezone) {
        this.startTime = moment
          .tz(this.data.itemStartUnix, "X", timezone)
          .format("MMM D, h:mm a");
        this.endTime = moment
          .tz(this.data.itemEndUnix, "X", timezone)
          .format("MMM D, h:mm a");
      } else {
        this.startTime = moment(this.data.itemStartUnix, "X").format(
          "MMM D, h:mm a"
        );
        this.endTime = moment(this.data.itemEndUnix, "X").format(
          "MMM D, h:mm a"
        );
      }
    }
  },
  mounted() {
    let that = this;
    let container = document.getElementsByClassName("bookingMap-left")[0];
    container.onmouseout = function (e) {
      let outSide = document.getElementsByClassName("bookingMap")[0];
      outSide.removeEventListener("mousewheel", that.fn);
    };

    // this.participants = this.data.attendees;
    if (this.data.hasOwnProperty("seat_id")) {
      this.getAllMap();
    } else {
      if (this.data.rooms.length > 0) {
        this.getAllMap();
      }
    }
  },
};
</script>

<style scoped lang="scss">
.bookingMap {
  background: #fff;
  height: 100%;
  //height: calc(100vh - 75px);
  //overflow-y: auto;

  .bookingMap-container {
    height: 100%;

    .bookingMap-left {
      .bookingMap-left-title {
        display: flex;
        height: 60px;
        line-height: 60px;
        justify-content: space-around;
        background: #f3f3f3;
      }

      .floorMapBlock {
        //height: calc(100% - 60px);
        height: calc(100vh - 60px);
        display: flex;
        align-items: center;
        justify-content: center;

        .mapTip {
          font-size: 20px;
        }
      }
    }

    .bookingMap-right-box {
      border: 1px solid #999999;
      height: 100%;
      padding: 0;

      .bookingMap-right {
        //height: 780px;
        //overflow-y: auto;
        overflow-x: hidden;
        width: calc(100% + 18px);

        .bookingMap-right-container {
          width: 100%;
          //height: 780px;
          //overflow-y: auto;
          //overflow-x: hidden;
          //width: calc(100% + 18px);
        }
      }
    }
  }
}
</style>
